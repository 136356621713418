<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p class="text-light">{{ error }}</p>
    </base-dialog>

    <base-dialog
      :showOk="false"
      :show="isLoading"
      title="Authenticating..."
      fixed
    >
      <base-spinner
        ><template #spinnerslot
          ><h4 class="text-light">Loading data...</h4></template
        ></base-spinner
      >
    </base-dialog>

    <base-card>
      <div class="row justify-content-center">
        <div class="col-sm-4">
          <form @submit.prevent="submitForm">
            <label for="email" class="fw-normal">Email </label>
            <input
              class="form-control mb-2"
              style="margin-bottom: 4px"
              type="text"
              id="email"
              v-model.trim="email"
              autocomplete="off"
            />

            <!-- <div class="form-control">
          <label for="email">E-Mail</label>
          <input type="email" id="email" v-model.trim="email" />
        </div> -->

            <label for="password" class="fw-normal mt-4">Password</label>
            <input
              class="form-control mb-2"
              type="password"
              id="password"
              v-model.trim="password"
            />

            <p v-if="!formIsValid">
              Please enter a valid email and password (must be at least 6
              characters long).
            </p>

            <base-button>{{ submitButtonCaption }}</base-button>
            <base-button type="button" mode="flat" @click="switchAuthMode">{{
              switchModeButtonCaption
            }}</base-button>
          </form>
        </div>
      </div>
    </base-card>
  </div>
</template>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
</style>

<script>
import { useAuthStore } from '../../stores/AuthStore';

export default {
  data() {
    return {
      email: '',
      password: '',
      formIsValid: true,
      mode: 'login',
      isLoading: false,
      error: null,
    };
  },
  computed: {
    submitButtonCaption() {
      if (this.mode === 'login') {
        return 'Login';
      } else {
        return 'Signup';
      }
    },
    switchModeButtonCaption() {
      if (this.mode === 'login') {
        return 'Signup instead';
      } else {
        return 'Login instead';
      }
    },
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === '' ||
        !this.email.includes('@') ||
        this.password.length < 6
      ) {
        this.formIsValid = false;
        return;
      }

      const actionPayload = {
        email: this.email,
        password: this.password,
      };

      try {
        if (this.mode === 'login') {
          this.isLoading = true;

          const authStore = useAuthStore();
          authStore
            .authenticateFirebase(actionPayload)
            .then(() => {
              this.$router.replace('/newtrans2');
            })
            .catch((error) => {
              this.error = 'Wrong username or/and password!';
              throw error;
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          console.log('authStore.registerNewUser');

          const authStore = useAuthStore();
          await authStore.registerNewUser(actionPayload);
        }
      } catch (err) {
        this.error = err.message || 'Failed to authenticate, try later.';
      }
    },

    switchAuthMode() {
      if (this.mode === 'login') {
        this.mode = 'signup';
      } else {
        this.mode = 'login';
      }
    },

    handleError() {
      this.error = null;
    },
  },
};
</script>
